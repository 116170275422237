'use strict';

(() => {
  document.addEventListener('turbolinks:load', function() {
    const elements = document.getElementsByClassName('fade-in-down');

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-in-down_go');
        }
      });
    });

    Array.prototype.forEach.call(elements, element => observer.observe(element));
  });
})();
