'use strict';

export const createElementFromHTML = (htmlString) => {
  const div = document.createElement('div');

  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

export const on = (eventType, selector, callback) => {
  document.body.addEventListener(eventType, function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
      if (target.matches(selector)) {
        callback.call(target, e);
        break;
      }
    }
  });
}

export const isHidden = (element) => {
  const style = window.getComputedStyle(element);

  return style.display === 'none' || style.visibility === 'hidden';
}

export const formNotice = (form, model) => {
  form.querySelectorAll(`[name^="${model}"]`).forEach((element) => {
    if (!isHidden(element)) {
      element.value = '';
    }
  });

  form.querySelectorAll('.validation-error, .note').forEach((elem) => elem.remove());

  const { notice } = form.dataset;

  const noticeHtml = `<div class="note note_info">${notice}</div>`;
  const noticeElement = createElementFromHTML(noticeHtml);

  form.insertBefore(noticeElement, form.firstChild);
}

export const formErrors = (form, model, errors) => {
  const inputs = form.querySelectorAll('input, select, textarea');

  form.querySelectorAll('.validation-error, .note').forEach((elem) => elem.remove());

  Object.entries(errors).forEach(([field, messages]) => {
    inputs.forEach((element) => {
      const name = element.getAttribute('name');
      const format = new RegExp(`${model}\\[${field}\\(?`);

      if (name && name.match(format)) {
        const errorTag = `<span class="validation-error">${messages.join(', ')}</span>`;
        const errorElement = createElementFromHTML(errorTag);

        element.insertAdjacentElement('afterend', errorElement);
      }
    });
  });
}

export const parents = (el, selector) => {
  const parents = [];

  while ((el = el.parentNode) && el !== document) {
    if (!selector || el.matches(selector)) parents.push(el);
  }

  return parents;
}

export const isNotElementTarget = (element, target) => (
  target !== element && !element.contains(target)
);

export const allElementsAreNotTarget = (elements, target) => (
  Array.from(elements).every(element => isNotElementTarget(element, target))
);
