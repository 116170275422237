'use strict';

import { on, formNotice, formErrors } from './helpers';

(() => {
  document.addEventListener('turbolinks:load', function() {
    on('ajax:success', 'form#new_feedback', function(e) {
      e.preventDefault();

      formNotice(this, 'feedback');
    })

    on('ajax:error', 'form#new_feedback', function(e, data, _status, _xhr) {
      e.preventDefault();

      const { errors } = e.detail[0];

      formErrors(this, 'feedback', errors);
    })
  });
})();
