'use strict';

import { on } from './helpers';

(() => {
  document.addEventListener('turbolinks:load', function() {
    on('click', '.flash__close', function(e) {
      e.preventDefault();

      this.closest('.flash').remove();
    });
  });
})();
